// Text.js
import React from 'react';

const stylesBig = {
  color: '#D70F0F',
  fontFamily: 'Figtree',
  fontSize: '3rem',
  fontStyle: 'normal',
  fontWeight: '700',
  lineHeight: 'normal',
  margin: 0,
  marginBottom: '20px',
};

const stylesMedium = {
  color: '#000',
  fontFamily: 'Figtree',
  fontSize: '2rem',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: 'normal',
  margin: 0,
  marginBottom: '2.5rem',
};

const stylesSmall = {
  color: '#292929',
  fontFamily: 'Figtree',
  fontSize: '1.5rem',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal',
  margin: 0,
  marginBottom: '2.5rem',
};

const stylesBold = {
  color: '#292929',
  fontFamily: 'Figtree',
  fontSize: 'inherit',
  fontStyle: 'normal',
  fontWeight: '700',
  lineHeight: 'normal',
};

/**
 * BigText Component
 * 
 * A styled paragraph component for displaying large text.
 * 
 * @param {Object} children - The text content to be displayed.
 * @param {Object} style - Additional styling for the component.
 * @returns {JSX.Element} - Returns the JSX element for the BigText component.
 */
export const BigText = ({ children, style }) => {
  return <p style={{ ...stylesBig, ...style }}>{children}</p>;
};

/**
 * MediumText Component
 * 
 * A styled paragraph component for displaying medium-sized text.
 * 
 * @param {Object} children - The text content to be displayed.
 * @param {Object} style - Additional styling for the component.
 * @returns {JSX.Element} - Returns the JSX element for the MediumText component.
 */
export const MediumText = ({ children, style }) => {
  return <p style={{ ...stylesMedium, ...style }}>{children}</p>;
};

/**
 * SmallText Component
 * 
 * A styled paragraph component for displaying small text.
 * 
 * @param {Object} children - The text content to be displayed.
 * @param {Object} style - Additional styling for the component.
 * @returns {JSX.Element} - Returns the JSX element for the SmallText component.
 */
export const SmallText = ({ children, style }) => {
  return <p style={{ ...stylesSmall, ...style }}>{children}</p>;
};

/**
 * BoldText Component
 * 
 * A styled paragraph component for displaying bold text.
 * 
 * @param {Object} children - The text content to be displayed.
 * @param {Object} style - Additional styling for the component.
 * @returns {JSX.Element} - Returns the JSX element for the BoldText component.
 */
export const BoldText = ({ children, style }) => {
  return <p style={{ ...stylesBold, ...style }}>{children}</p>;
};
