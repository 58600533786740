import { useState, useEffect, useContext } from 'react';
import { ProfileCard } from './ProfileCard';
import { useNavigate, useLocation } from 'react-router-dom';

import Dummy1 from '../../../../images/dummy1.png';
import Dummy2 from '../../../../images/dummy2.png';
import Dummy3 from '../../../../images/dummy3.png';
import AddKidsFirst from '../../../../images/AddKidsFirst.png';
import { getChildInfo } from '../../../../api';
import { useUser } from '../../../../UserContext';
import { getWishlist } from '../../../../api';
import LoaderContext from '../../../../context/LoaderContext';
import LetterPreviewModal from '../../../../components/LetterPreviewModal';
import AddChildImg from '../../../../images/plus_img.png';

import bgAudio from '../../../../images/audios/intro.mp3';
import { Button } from '../../../../components/Button';

export const SelectProfile = ({ setStep, setSelectedProfile }) => {
  const [activeProfile, setActive] = useState();
  const [childDetails, setChildDetails] = useState(null);
  const [wishList, setWishList] = useState([]);
  const { userData, userId } = useUser();
  const { setIsLoading, setMessage } = useContext(LoaderContext);
  const [isLoaded, setIsLoaded] = useState(false);
  const [letterContent, setLetterContent] = useState({});
  const [open, setOpen] = useState(false);
  const [openLetterAlert, setOpenLetterAlert] = useState(false);
  const navigate = useNavigate(); // Initialize navigate
  const location = useLocation(); // Get the current location

  useEffect(() => {
    setIsLoading(true);
    setMessage('Fetching kid profiles...');
    getChildInfo({ userId: userId || userData.userInfo.userid }).then(res => {
      setIsLoading(false);
      setMessage('');
      setChildDetails(res.data.childInfo);
      setIsLoaded(true);
    });
  }, []);

  useEffect(() => {
    getWishlist({ userid: userId || userData.userInfo.userid }).then(res => {
      setWishList(res.data.wishList);
    });
  }, []);

  const avatars = [Dummy1, Dummy2, Dummy3];

  let i = -1;
  const profiles =
    childDetails &&
    childDetails.map(child => {
      if (child.gender === 'girl') {
        return {
          id: child.childid,
          name: child.childname,
          avatar: avatars[2],
          age: child.childage,
          gender: child.gender,
        };
      }
      i++;
      if (i === 2) {
        i = 0;
      }
      return {
        id: child.childid,
        name: child.childname,
        avatar: avatars[i],
        age: child.childage,
        gender: child.gender,
      };
    });

  if (isLoaded && (!childDetails || childDetails.length === 0)) {
    return (
      <div className="fetch-data" style={{ marginTop: 0 }}>
        {/* Image for no kids profile */}
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <img src={AddKidsFirst} alt="No Child Profiles Added" style={{ width: '60vh', height: 'auto' }} />
          {location.pathname !== '/kid' && (
            <Button onClick={() => navigate('/parent/profile')} style={{ width: '40%', alignSelf: 'center' }}>
              Add Child
            </Button>
          )}
        </div>
      </div>
    );
  }

  const handleLetterPreview = childInfo => {
    console.log({ childInfo });
    if (childInfo.lettertemplateurl) {
      setLetterContent({
        name: childInfo.lettercontent.name,
        age: childInfo.lettercontent.age,
        behaviour: childInfo.lettercontent.haveBeen,
        niceThings: childInfo.lettercontent.did,
        thingsNextYear: childInfo.lettercontent.willDo,
        gratefulFor: childInfo.lettercontent.greatful,
        wishes: childInfo.lettercontent.wishlist,
        position: childInfo.lettercontent.position,
        templateUrl: childInfo.lettertemplateurl,
      });

      setOpen(true);
    } else {
      setOpenLetterAlert(true);
    }
  };

  const handleClose = () => {
    if (open) {
      setOpen(false);
    }
    if (openLetterAlert) {
      setOpenLetterAlert(false);
    }
  };

  // Handle the "Launch Full Screen" button click
  const handleLaunchFullScreen = () => {
    navigate('/kid'); // Navigate to the /kid route
  };

  return (
    <>
      <div>
        {childDetails && childDetails.length > 0 && (
          <div className="kids-profile" style={{ marginTop: 0 }}>
            <div style={{ marginBottom: '0rem', width: '100%' }}>
              <p className="page-heading">Select a child profile</p>

              {/* Only play the audio when the route is /kid */}
              {location.pathname === '/kid' && <audio src={bgAudio} autoPlay loop />}

              <p className="page-subtext">
                Choose from the child's list below to create your unique letter to Santa and make your special holiday
                wishes come true.
                <br />
                <span style={{ color: 'grey', fontStyle: 'italic' }}>
                  Note: A green checkmark on the profile indicates that the letter has already been submitted.
                </span>
              </p>

              {/* Only show the "Launch Full Screen" button if the user is on /parent/kid */}
              {location.pathname === '/parent/kid' && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center', // Horizontally center
                  }}
                >
                  <button
                    onClick={handleLaunchFullScreen}
                    style={{
                      backgroundColor: '#AC0101', // Red background
                      color: 'white', // White text color
                      padding: '10px 20px', // Padding for the button
                      border: 'none', // Remove the border
                      borderRadius: '20px', // Rounded corners
                      cursor: 'pointer', // Pointer cursor on hover
                      fontSize: '20px', // Font size
                    }}
                  >
                    View In Full Screen
                  </button>
                </div>
              )}
            </div>
            <div
              style={{
                display: 'flex',
                gap: '1rem',
                justifyContent: 'center',
                flexWrap: 'wrap',
                padding: '20px',
              }}
            >
              {profiles.map(profile => {
                const childInfo = wishList.find(li => li.childid === profile.id);
                const sentToSanta = !!childInfo;
                return (
                  <ProfileCard
                    avatar={profile.avatar}
                    name={profile.name}
                    isActive={profile.id === activeProfile}
                    key={profile.id}
                    setActive={() => {
                      if (!sentToSanta) {
                        if (location.pathname === '/parent/audio') {
                          setStep(1);
                        } else {
                          setStep(3);
                        }
                        setActive(profile.id);
                        setSelectedProfile(profile);
                      } else {
                        handleLetterPreview(childInfo);
                      }
                    }}
                    sentToSanta={sentToSanta}
                  />
                );
              })}
              {location.pathname !== '/kid' && (
                <ProfileCard
                  avatar={AddChildImg}
                  name={'Add Child'}
                  setActive={() => {
                    navigate('/parent/profile');
                  }}
                />
              )}
            </div>
          </div>
        )}
      </div>
      <LetterPreviewModal
        open={open}
        letterContent={letterContent}
        openLetterAlert={openLetterAlert}
        handleClose={handleClose}
      />
    </>
  );
};
