import React, { useState, useRef, useEffect, useContext, useMemo, useCallback } from 'react';
import axios from 'axios';
import SantaTalkVideo from '../../../../images/chat-with-santa-video-new.mp4';
import './style.css';
// import AudioRecorder from './components/AudioRecorder';
import { Button } from '../../../../components/Button';
import { getProductList, saveWishList } from '../../../../api';
import { ProductCarousel } from '../../../parent/components/Wishlist/components/ProductCarousel/ProductCarousel';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { useUser } from '../../../../UserContext';
import ProductDisplay from './ProductDisplay';
import ringtoneAudio from '../../../../images/ringtone.mp3';
import { useMediaQuery } from '@mui/material';
import { textAfterProductFetched, textAfterProductSelected, textIfDelays, initialAudioFiles } from './constants';
import { IconButton, Badge } from '@mui/material';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../../../firebase';
import giftBox from '../../../../images/gift-box.jpeg';
import Loader from './Loader';

export default function SantaTalks({ setStep, selectedProfile, setTurnOffMicrophoneInfo }) {
  // Refs
  const videoRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const audioContextRef = useRef(null);
  const processorNodeRef = useRef(null);
  const inputNodeRef = useRef(null);
  const streamRef = useRef(null);
  const silenceStartRef = useRef(null);
  const isSpeakingRef = useRef(false);
  const isRecordingRef = useRef(false);
  const audioRef = useRef(null);
  const isSantaSpeakingRef = useRef(true);
  const countRef = useRef(0);
  const { userData, userId } = useUser();
  const ringToneAudioRef = useRef(null);
  const isFetchedRef = useRef(null);
  const handleSelectRef = useRef(false);

  const textAfterProductFetched_2 = useRef([...textAfterProductFetched]);
  const textAfterProductSelected_2 = useRef([...textAfterProductSelected]);
  const textIfDelays_2 = useRef([...textIfDelays]);
  const initialAudioFiles_2 = useRef([...initialAudioFiles]);

  // State
  const [audioToTextData, setAudioToTextData] = useState(null);
  const [audioData, setAudioData] = useState(null);
  const [playVideo, setPlayVideo] = useState(false);
  const [sellerProductList, setSellerProductList] = useState([]);
  const [disableButtonOnEnd, setDisableButtonOnEnd] = useState(false);
  const [attempts, setAttempts] = useState(3);
  const [productLoader, setProductsLoader] = useState(false);

  // Constants
  const SILENCE_THRESHOLD = 0.1; // Adjust based on environment
  const SILENCE_WAIT_TIME = 2500; // 1.5 seconds of silence

  // Derived values
  const isFetched = !!sellerProductList.length;
  const smallScreen = useMediaQuery('(max-width:480px)');

  const introText = [
    `Ho-Ho-Ho!!!!   Well hello ${selectedProfile.name}!  It is so nice to see you again.   
  I have been watching you all year, and I know that you have been a very good ${selectedProfile.gender}!   
  I am happy to say….. you are on the NICE LIST! Now we get to have a little fun together.
  I hope you have your Wish List handy.  Remember, I can only get things you can get at the store.
  No puppies or ponies!  So please tell me the name of the toy or game that you want THE MOST this year?!?!  Ho Ho Ho!`,
  ];

  useEffect(() => {
    // Start monitoring when the component mounts and handle cleanup when unmounted
    // startMonitoring();

    return () => {
      stopRecordingIfNeeded();
      pauseAudioIfPlaying();

      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
      if (processorNodeRef.current) {
        processorNodeRef.current.disconnect();
      }
      if (inputNodeRef.current) {
        inputNodeRef.current.disconnect();
      }
      clearTimeout(normalSilenceTimeoutRef.current);
      clearTimeout(sessionSilenceTimeoutRef.current);
    };
  }, []);

  useEffect(() => {
    const handleMouseMove = event => {
      clearTimeout(sessionSilenceTimeoutRef.current);
      sessionSilenceTimeoutRef.current = startGlobalTimer();
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      clearTimeout(sessionSilenceTimeoutRef.current);
      sessionSilenceTimeoutRef.current = startGlobalTimer();
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    ringToneAudioRef.current.play();
    fetchAndPlayAudio(introText[0], false, 'intro');
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (audioToTextData && audioToTextData.length >= 5) {
        // Fetch and play audio based on recognized speech, and retrieve products
        // const prompt = `If input is related to wishlist in below statement please give some options or ask for wishlist \n ${audioToTextData}`;
        if (!isSantaSpeakingRef.current && !isSpeakingRef.current) {
          const randomNumber = Math.floor(Math.random() * initialAudioFiles_2.current.length);
          const audio = new Audio(initialAudioFiles_2.current[randomNumber].url);
          audioRef.current = audio;
          setAudioData(audio);
          setPlayVideo(true);
          isSantaSpeakingRef.current = true;

          initialAudioFiles_2.current.splice(randomNumber, 1);
          if (initialAudioFiles_2.current.length === 0) {
            initialAudioFiles_2.current = [...initialAudioFiles];
          }
        }

        const products = await getProductsFromSpeech(audioToTextData);
        const productText = products.length > 1 ? `That` : `${products[0]}`;

        if (products && products.length) {
          setAttempts(3);
          const randomNumber = Math.floor(Math.random() * textAfterProductFetched_2.current.length);
          isFetchedRef.current = true;
          await fetchAndPlayAudio(
            // `${productText} is a great toy idea! My elves are working on a few of those! Do you want to select your favorite from this list?`,
            textAfterProductFetched_2.current[randomNumber],
            false
          );
          textAfterProductFetched_2.current.splice(randomNumber, 1);
          if (textAfterProductFetched_2.current.length === 0) {
            textAfterProductFetched_2.current = [...textAfterProductFetched];
          }
        } else if (audioToTextData.length > 5) {
          if (attempts <= 1) {
            setDisableButtonOnEnd(true);
            await fetchAndPlayAudio(
              `Hey ${selectedProfile.name}, Threre is no relevent input from you since a long time. Thanks for talking. Have a greate day!`,
              false,
              'noInputExit'
            );
          } else {
            await fetchAndPlayAudio(
              `Hey ${selectedProfile.name}, I am sorry! Could you please repeat your wish or Do you have another toy or game that is on your Wish List? Right now, we can only make things that you can get from the store.`,
              false
            );
          }
          setAttempts(prev => prev - 1);
        }
      }
    };

    fetchData(); // Call the async function inside useEffect
  }, [audioToTextData]);

  const stopRecordingIfNeeded = () => {
    mediaRecorderRef.current = null;
    isRecordingRef.current = null;
  };

  useEffect(() => {
    if (audioData && playVideo) {
      syncAudioVideoPlayback();
    }
  }, [audioData, playVideo]);

  // Helper Functions
  const syncAudioVideoPlayback = () => {
    const audio = audioRef.current;
    const video = videoRef.current;

    if (!audio || !video) return;

    // Reset media positions
    audio.currentTime = 0;
    video.currentTime = 0;

    let audioReady = false;
    let videoReady = false;

    // Helper to play both once ready
    const tryPlayBoth = () => {
      if (audioReady && videoReady) {
        audio.play().catch(error => {
          console.warn(`Audio failed to play: ${error.message}`);
        });
        video.play().catch(error => {
          console.warn(`Video failed to play: ${error.message}`);
        });
      }
    };

    // Add event listeners to check readiness
    const onAudioReady = () => {
      audioReady = true;
      tryPlayBoth();
    };

    const onVideoReady = () => {
      videoReady = true;
      tryPlayBoth();
    };

    audio.addEventListener('canplaythrough', onAudioReady, { once: true });
    video.addEventListener('canplaythrough', onVideoReady, { once: true });

    // Synchronize end handling
    const handleEnd = () => {
      audio.pause();
      video.pause();
      setPlayVideo(false);
      isSantaSpeakingRef.current = false;
    };

    // Attach 'ended' listeners
    audio.addEventListener('ended', handleEnd);
    video.addEventListener('ended', handleEnd);

    // Cleanup event listeners
    return () => {
      audio.removeEventListener('canplaythrough', onAudioReady);
      video.removeEventListener('canplaythrough', onVideoReady);
      audio.removeEventListener('ended', handleEnd);
      video.removeEventListener('ended', handleEnd);
    };
  };

  const pauseAudioIfPlaying = () => {
    const audio = audioRef.current;
    const video = videoRef.current;

    if (audio && !audio.paused) {
      audio.pause();
    }
    if (video && !video.paused) {
      video.pause();
    }
  };

  const fetchAndPlayAudio = async (textData, isGptRequired, type) => {
    try {
      const response = await axios.post(
        'https://jollywishlist.com/openai/api/synthesize',
        { text: textData, isGptRequired },
        { responseType: 'arraybuffer' } // Important for handling audio response
      );

      isSantaSpeakingRef.current = false;
      // Pause and reset current audio and video
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
        URL.revokeObjectURL(audioRef.current.src); // Free memory
      }
      if (videoRef.current) {
        videoRef.current.pause();
        videoRef.current.currentTime = 0;
        setPlayVideo(false);
      }

      setPlayVideo(true);
      ringToneAudioRef.current.pause();
      isSantaSpeakingRef.current = true;
      // Create a Blob from the response data
      const audioBlob = new Blob([response.data], { type: 'audio/wav' });
      const audioUrl = URL.createObjectURL(audioBlob);

      // Create an audio element and play it
      const audio = new Audio(audioUrl);
      audioRef.current = audio;
      setAudioData(audio);
      if (type === 'exit') {
        setTimeout(() => {
          setStep(5);
          setTurnOffMicrophoneInfo(true);
        }, 28000);
      }
      if (type === 'intro') {
        startMonitoring();
      }
      if (type === 'productSelection') {
        handleSelectRef.current = false;
      }

      if (type === 'noInputExit') {
        setTimeout(() => {
          setStep(0);
          setTurnOffMicrophoneInfo(true);
        }, 7000);
      }
    } catch (error) {
      console.error('Error fetching audio:', error);
    }
  };

  const getProductsFromSpeech = async text => {
    try {
      setProductsLoader(true);
      const response = await axios.post('https://jollywishlist.com/openai/api/conversation', {
        prompt: `Get me product names from the below statement in JSON format:\n ${text}.
                Please return the response as a JSON array like this: ["product1", "product2", ...]`,
      });
      setProductsLoader(false);
      const str = response.data.data.content;
      const productNames = (str.match(/"([^"]+)"/g) || []).map(name => name.replace(/"/g, '').trim());
      const cleanedProductNames = (productNames || []).map(name => name.replace(/[.,]/, '').trim());
      if (cleanedProductNames && cleanedProductNames.length) {
        const productsResponse = await getProductList({ productList: cleanedProductNames });
        const productArr = productsResponse.data.result.reduce((productArr, prod, i) => {
          productArr.push({
            itemName: prod.itemName,
            rank: sellerProductList.length + i + 1,
            productList: prod.productList.map(product => {
              return {
                ...product,
                itemName: prod.itemName,
                selected: false,
                position: sellerProductList.length + i,
              };
            }),
          });

          return productArr;
        }, []);

        setSellerProductList(prev => [...prev, ...productArr]);
      }

      return cleanedProductNames;
    } catch (error) {
      setProductsLoader(false);
      console.log('Error occured', error);
    }
  };

  const getMicrophoneAccess = async () => {
    try {
      streamRef.current = await navigator.mediaDevices.getUserMedia({ audio: true });
      console.log('Microphone access granted!');
      return streamRef.current;
    } catch (err) {
      console.error('Microphone access denied:', err);
    }
  };

  const startMonitoring = async () => {
    const stream = await getMicrophoneAccess();
    if (stream) {
      // Set up AudioContext for processing audio
      audioContextRef.current = new AudioContext();
      processorNodeRef.current = audioContextRef.current.createScriptProcessor(2048, 1, 1); // Small buffer size
      inputNodeRef.current = audioContextRef.current.createMediaStreamSource(stream);

      // Real-time processing for detecting speech or silence
      processorNodeRef.current.onaudioprocess = detectSpeech;
      inputNodeRef.current.connect(processorNodeRef.current);
      processorNodeRef.current.connect(audioContextRef.current.destination); // Audio output
    }
  };

  const startGlobalTimer = () => {
    const timer = setTimeout(() => {
      fetchAndPlayAudio(
        'There is no input from your end since a long time. Thanks for talking, Have a greate day',
        false,
        'noInputExit'
      );
      setDisableButtonOnEnd(true);
    }, 60000);

    return timer;
  };

  const normalSilenceTimeoutRef = useRef();
  const sessionSilenceTimeoutRef = useRef();
  const SPEAKING_THRESHOLD = 20000;
  const speakingStartRef = useRef(null);

  const detectSpeech = event => {
    const inputBuffer = event.inputBuffer.getChannelData(0); // Get the audio data
    let sum = 0.0;

    // Calculate the average volume of the current buffer
    for (let i = 0; i < inputBuffer.length; ++i) {
      sum += inputBuffer[i] * inputBuffer[i];
    }
    const averageVolume = Math.sqrt(sum / inputBuffer.length); // RMS calculation

    // If speaking detected, start recording if not already started
    if (averageVolume >= SILENCE_THRESHOLD) {
      if (!isSpeakingRef.current && !isSantaSpeakingRef.current) {
        isSpeakingRef.current = true;
        console.log('User started speaking...');
        startRecording(); // Start recording when speech is detected
      }
      silenceStartRef.current = null; // Reset silence timer

      if (normalSilenceTimeoutRef.current) {
        clearTimeout(normalSilenceTimeoutRef.current);
        normalSilenceTimeoutRef.current = null;
      }
      if (sessionSilenceTimeoutRef.current) {
        clearTimeout(sessionSilenceTimeoutRef.current);
        sessionSilenceTimeoutRef.current = null;
      }
    } else {
      // Silence detected
      console.log('isSpeakingRef.current', isSpeakingRef.current);
      if (isSpeakingRef.current) {
        if (!silenceStartRef.current) {
          clearTimeout(normalSilenceTimeoutRef.current);
          normalSilenceTimeoutRef.current = null;
          silenceStartRef.current = Date.now(); // Start silence timer

          if (!speakingStartRef.current) {
            // Start tracking continuous speaking time
            speakingStartRef.current = Date.now();
          }

          // Check if the user has spoken continuously for 1 minute
          if (Date.now() - speakingStartRef.current >= SPEAKING_THRESHOLD) {
            console.log('User has spoken continuously for 20 secs');
            // Reset speakingStartRef to avoid multiple triggers
            speakingStartRef.current = null;
            // Add your action here for continuous speaking
            stopRecording();
          }
        } else if (Date.now() - silenceStartRef.current >= SILENCE_WAIT_TIME) {
          console.log('User stopped speaking for 1.5 seconds...');
          clearTimeout(normalSilenceTimeoutRef.current);
          normalSilenceTimeoutRef.current = null;
          stopRecording(); // Stop recording after silence
          isSpeakingRef.current = false;
          silenceStartRef.current = null; // Reset silence start time
          speakingStartRef.current = null;
        }
      }

      if (!normalSilenceTimeoutRef.current && !isSantaSpeakingRef.current && !isSpeakingRef.current) {
        console.log('No input');
        normalSilenceTimeoutRef.current = setTimeout(() => {
          const randomNumber = Math.floor(Math.random() * textIfDelays_2.current.length);
          if (!isSantaSpeakingRef.current && !isSpeakingRef.current && !handleSelectRef.current) {
            if (isFetchedRef.current) {
              fetchAndPlayAudio(textIfDelays_2.current[randomNumber], false);
              textIfDelays_2.current.splice(randomNumber, 1);
              if (textIfDelays_2.current.length === 0) {
                textIfDelays_2.current = [...textIfDelays];
              }
            } else {
              fetchAndPlayAudio('Feel free to share your wish for this eve');
            }
          }
        }, 15000); // 5 seconds of normal silence
      }

      if (!sessionSilenceTimeoutRef.current && !isSpeakingRef.current) {
        console.log('No input');
        if (!isSpeakingRef.current && !handleSelectRef.current) {
          sessionSilenceTimeoutRef.current = startGlobalTimer();
        }
      }

      if (isSantaSpeakingRef.current || isSpeakingRef.current) {
        console.log('Someone Speaking');
        clearTimeout(normalSilenceTimeoutRef.current);
        normalSilenceTimeoutRef.current = null;
      }

      if (isSpeakingRef.current || handleSelectRef.current) {
        clearTimeout(sessionSilenceTimeoutRef.current);
        sessionSilenceTimeoutRef.current = null;
      }
    }
  };

  const startRecording = () => {
    // Initialize MediaRecorder when the user starts speaking
    mediaRecorderRef.current = new MediaRecorder(streamRef.current);
    audioChunksRef.current = []; // Clear previous audio chunks

    mediaRecorderRef.current.ondataavailable = event => {
      audioChunksRef.current.push(event.data); // Capture audio chunk during recording
    };

    mediaRecorderRef.current.onstop = async () => {
      const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
      const audioUrl = URL.createObjectURL(audioBlob);

      // Send audio data to the server or process it
      const formData = new FormData();
      formData.append('audio', audioBlob, 'audio.wav');

      // Example API request to process the audio (replace with your own API)
      try {
        const response = await axios.post('https://jollywishlist.com/openai/api/recognize', formData);
        const audioTextData = response.data;

        // Display transcribed text
        if (audioTextData && audioTextData.transcript) {
          setAudioToTextData(audioTextData.transcript);
        }
      } catch (error) {
        console.log('Error occured', error);
      }
    };

    mediaRecorderRef.current.start();
    isRecordingRef.current = true;
    console.log('Recording started...');
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecordingRef.current) {
      mediaRecorderRef.current.stop();
      isRecordingRef.current = false;
      console.log('Recording stopped');
    }
  };

  const hadndleSelectProduct = selectedProduct => {
    setSellerProductList(
      sellerProductList.map((prod, i) => {
        if (prod.itemName === selectedProduct.itemName) {
          return {
            ...prod,
            productList: prod.productList.map(product => {
              if (product.itemId === selectedProduct.itemId) {
                return {
                  ...product,
                  selected: !selectedProduct.selected,
                };
              }
              return product;
            }),
          };
        }
        return prod;
      })
    );
    countRef.current += !selectedProduct.selected ? 1 : -1;

    if (!selectedProduct.selected && !isSantaSpeakingRef.current) {
      handleSelectRef.current = true;

      if (normalSilenceTimeoutRef.current) {
        clearTimeout(normalSilenceTimeoutRef.current);
        normalSilenceTimeoutRef.current = null;
      }
      if (sessionSilenceTimeoutRef.current) {
        clearTimeout(sessionSilenceTimeoutRef.current);
        sessionSilenceTimeoutRef.current = null;
      }

      const randomNumber = Math.floor(Math.random() * textAfterProductSelected_2.current.length);
      debouncedFunction(textAfterProductSelected_2.current[randomNumber]);
      textAfterProductSelected_2.current.splice(randomNumber, 1);
      if (textAfterProductSelected_2.current.length === 0) {
        textAfterProductSelected_2.current = [...textAfterProductSelected];
      }
    }
  };

  const handleSaveWishlist = () => {
    const payload = {
      userId: userId || userData.userInfo.userid,
      childWish: [
        {
          childid: selectedProfile.id,
          wishlistitem: sellerProductList.map(item => item.itemName),
          lettertemplateurl: null,
          lettercontent: {
            name: selectedProfile.name,
            age: selectedProfile.age,
            haveBeen: null,
            did: null,
            willDo: null,
            greatful: null,
            wishlist: null,
            position: null,
          },
          selectedproducts: sellerProductList
            .map(item =>
              item.productList.reduce((acc, product) => {
                if (product.selected) {
                  acc.push(product.itemId);
                }
                return acc;
              }, [])
            )
            .flat(),
        },
      ],
    };
    saveWishList(payload).then(() => {
      logEvent(analytics, 'Wishlist Created via Conversation', { details: payload });
    });
  };

  const debounceSelection = (func, delay) => {
    let timer;
    return args => {
      clearTimeout(timer);
      timer = setTimeout(() => func(args), delay);
    };
  };

  const debouncedFunction = useCallback(
    debounceSelection(inputValue => fetchAndPlayAudio(inputValue, false, 'productSelection'), 6000),
    []
  );

  const allSelectedProducts = useMemo(() => {
    return sellerProductList
      .map(item =>
        item.productList.reduce((acc, product) => {
          if (product.selected) {
            acc.push(product.itemId);
          }
          return acc;
        }, [])
      )
      .flat();
  }, [sellerProductList]);

  const endConversation = () => {
    if (allSelectedProducts?.length) {
      handleSaveWishlist();
      fetchAndPlayAudio(
        `HO HO HO!!! That is quite a list ${selectedProfile.name}!!  Well, it looks like we are at the end of our time today.  
        Let me take this Wish List back to the Toy Work Shop and have the elves get started right away!  
        It was a pleasure talking to you and I hope you have the Happiest of Holidays!  Thanks for being such a good  ${selectedProfile.gender}
        Don’t forget to leave some cookies and milk for me, and some carrots for Rudolph and the reindeer!  HO HO HO!`,
        false,
        'exit'
      );
      setDisableButtonOnEnd(true);
    } else {
      setStep(0);
    }
  };

  const capitalizeFirstLetterOfEachWord = str => {
    return str
      ?.split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  return (
    <>
      <div className="santa-talk-container-outer">
        {isFetched && (
          <div className="santa-talk-container-outer-2">
            {productLoader ? (
              <Loader />
            ) : (
              <>
                {[...sellerProductList].reverse().map((item, i) => (
                  <div className="santa-talk-container-main" key={i}>
                    <div className="santa-talk-product-title">
                      Gift {item.rank} : <span>{capitalizeFirstLetterOfEachWord(item.itemName)}</span>
                    </div>
                    <div className="santa-talk-container-products">
                      {item.productList.map((product, index) => (
                        <ProductDisplay key={index} product={product} handleSelect={hadndleSelectProduct} />
                      ))}
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        )}

        <div className={`video-and-button-container ${isFetched ? 'fetched' : 'not-fetched'}`}>
          <video
            className={`video-container ${isFetched ? 'small' : 'large'}`}
            src={SantaTalkVideo}
            loop
            muted
            ref={videoRef}
          />

          {setStep && (
            <div className={`conversion-buttons ${isFetched ? 'overlay' : ''}`}>
              <Button
                onClick={endConversation}
                disabled={disableButtonOnEnd}
                style={{ fontSize: smallScreen ? '10px' : '15px' }}
              >
                {isFetched ? 'All Done' : 'Hang Up'}
              </Button>
            </div>
          )}
        </div>
        {isFetched && (
          <div className="selection-done">
            <IconButton color="primary" aria-label="cart">
              <Badge
                badgeContent={countRef.current || 0}
                color="secondary"
                showZero
                sx={{
                  '& .MuiBadge-badge': {
                    backgroundColor: '#ac0101', // Set badge background color to red
                    color: 'white', // Text color inside the badge
                    top: '-25%', // Adjust vertical alignment
                    right: '50%', // Adjust horizontal alignment
                    transform: 'translate(50%, -50%)', // Center the badge
                  },
                }}
              >
                <img src={giftBox} width={40} height={40} alt="Gift Box" />
              </Badge>
            </IconButton>
          </div>
        )}
      </div>
      <audio ref={ringToneAudioRef} src={ringtoneAudio} loop />
    </>
  );
}
