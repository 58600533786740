// Importing necessary components, styles, and utilities
import { LogoBtn } from '../Button';
import { Logo } from '../Logo';
import UserIcon from '../../images/user.svg';
import './index.css';
import { useUser } from '../../UserContext';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import HomeImg from '../../images/HomeImg.png';

/**
 * AppHeader Component
 *
 * @param {Object} props - React component props.
 * @param {boolean} props.showParentSignIn - Flag indicating whether to show the Parent Sign In button.
 * @param {boolean} props.loggedIn - Flag indicating whether the user is logged in.
 * @returns {ReactNode} - Rendered component with app logo and optional Parent Sign In button.
 */
export const AppHeader = ({ showParentSignIn, loggedIn, showHomeIcon }) => {
  // Accessing user-related functions and data from UserContext
  const { setUserData } = useUser();
  const isMobile = useMediaQuery('(max-width:900px)');
  const logoStyles = isMobile ? { height: '4rem' } : {};
  const logoBtnStyle = isMobile ? { fontSize: '15px' } : {};

  // Accessing navigation function from react-router-dom
  const navigate = useNavigate();

  // Function to handle Parent Sign In and log out actions
  const gotToHomePage = () => {
    // Clearing user data and removing stored information on sign out
    setUserData({});
    localStorage.removeItem('latestUserId');
    localStorage.removeItem('user');
    Cookies.remove('userId');

    // Navigating to the home page after sign out
    navigate('/');
  };

  // Rendering the app header with logo and optional Parent Sign In button
  return (
    <div className="app-header">
      {/* App logo component */}
      <Logo style={logoStyles} />

      {/* Conditional rendering of Parent Sign In button based on props */}
      {showParentSignIn && !loggedIn && (
        <LogoBtn logo={UserIcon} text="Parent Login" onClick={gotToHomePage} style={logoBtnStyle} />
      )}
      {!!showHomeIcon && (
        <div onClick={gotToHomePage} style={{ cursor: 'pointer' }}>
          <img width="45" src={HomeImg} />
        </div>
      )}
    </div>
  );
};
