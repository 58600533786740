import { ContentBox } from '../../components/ContentBox';
import { HomeHeader } from '../../components/HomeHeader';
import './index.css';
import { BigText, MediumText, SmallText } from '../../components/Text';
import { Link } from 'react-router-dom';
import { Button } from '../../components/Button';
import { useContext, useEffect, useState, useRef } from 'react';
import LoaderContext from '../../context/LoaderContext';
import section1Img from '../../images/home-page-section1.png';
import section2Img from '../../images/home-page-section2.png';
import section3Img1 from '../../images/home-page-section3-img1.png';
import section3Img2 from '../../images/home-page-section3-img2.png';
import section3Img3 from '../../images/home-page-section3-img3.png';
import section4Img from '../../images/home-page-section4.png';
import section5Img from '../../images/home-page-section5.png';
import HeroBgVideo from '../../images/santa-landing-page-video.mp4';
import HeroBgMobile from '../../images/home-page.jpeg';
import Footer from '../../components/Footer';
import { StarOne, StarTwo, StarThree } from '../../components/SparkleEffect';
import SnowfallEffect from '../../components/SnowfallEffect';
import bgAudio from '../../images/audios/intro.mp3';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import IconButton from '@mui/material/IconButton';

const HeroBg = require('../../images/hero-bg.png');
const Content1 = require('../../images/content1.png');
const Works1 = require('../../images/Works1.png');
const Works2 = require('../../images/Works2.png');
const Works3 = require('../../images/Works3.png');
const Arrow = require('../../images/arrow-right.png');
const Content3 = require('../../images/Content3.png');
const Content4 = require('../../images/Content4.png');

export const HomePage = () => {
  const [openRegister, setOpenRegister] = useState(false);
  const [openSignIn, setOpenSignIn] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const audioRef = useRef(null);
  const videoRef = useRef(null);
  const currentUserId = sessionStorage.getItem('currentUserId');
  const { setIsLoading } = useContext(LoaderContext);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  const handleLetsGetStarted = () => {
    if (currentUserId) {
      setOpenSignIn(true);
    } else {
      setOpenRegister(true);
    }
  };

  const handleMuteToggle = () => {
    setIsMuted((prev) => {
      const newMuteState = !prev;
      if (audioRef.current) {
        audioRef.current.muted = newMuteState; // Mute or unmute the audio
      }
      if (videoRef.current) {
        videoRef.current.muted = newMuteState; // Mute or unmute the video
      }
      return newMuteState;
    });
  };
  
  // Home page

  return (
    <div className="home">
      <div
        className="hero"
        style={{
          position: 'relative',
          overflow: 'hidden',
          backgroundImage: `url(${HeroBgMobile})`,
        }}
      >
        <SnowfallEffect numFlakes={150} />
        <HomeHeader
          openRegister={openRegister}
          setOpenRegister={setOpenRegister}
          openSignIn={openSignIn}
          setOpenSignIn={setOpenSignIn}
        />
        <IconButton
          onClick={handleMuteToggle}
          color="white"
          aria-label={isMuted ? 'Unmute' : 'Mute'}
          style={{
            position: 'absolute',
            top: '40px',
            right: '40px',
            zIndex: 10, // Make sure the button stays on top of other content
          }}
        >
          {isMuted ? <VolumeOffIcon style={{ color: 'white' }} /> : <VolumeUpIcon style={{ color: 'white' }} />}
        </IconButton>
        <div className="hero-text">
          <div>
            <p className="small">Experience the</p>
            <p className="big">Modern Magic of Christmas</p>
            <p className="normal">
              Kids can have fun talking and emailing Santa Claus, while building their own personal online Wish List
            </p>
          </div>
        </div>
      </div>

      <div className="hero-container">
        <HomeHeader
          openRegister={openRegister}
          setOpenRegister={setOpenRegister}
          openSignIn={openSignIn}
          setOpenSignIn={setOpenSignIn}
        />
        <audio ref={audioRef} src={bgAudio} autoPlay loop />
        <video className="video-background" ref={videoRef} src={HeroBgVideo} autoPlay loop muted playsInline></video>

        {/* Mute/Unmute Button */}
        <IconButton
          onClick={handleMuteToggle}
          color="white"
          aria-label={isMuted ? 'Unmute' : 'Mute'}
          style={{
            position: 'absolute',
            top: '20px',
            right: '20px',
            zIndex: 10, // Make sure the button stays on top of other content
          }}
        >
          {isMuted ? <VolumeOffIcon style={{ color: 'white' }} /> : <VolumeUpIcon style={{ color: 'white' }} />}
        </IconButton>

        <div className="hero-text">
          <div className="hero-text-content">
            <p className="small">Experience the</p>
            <p className="big">
              Modern Magic of Christmas <StarOne />
            </p>
            <p className="normal">
              Kids can have fun talking and emailing Santa Claus, while building their own personal online Wish List
              <StarThree />
            </p>
          </div>
        </div>
      </div>

      <ContentBox>
        <div className="content-1" style={{ paddingBottom: '4rem', borderBottom: '1px solid #D9D9D9' }}>
          <div className="content-1-text">
            <BigText style={{ fontSize: '2.5rem' }}>
              The Holiday Season is a time of wonder, joy and heartfelt connections
            </BigText>
            <MediumText style={{ fontSize: '1.5rem' }}>
              And what better way to celebrate the spirit of the season than by reaching out to Santa Claus himself.
            </MediumText>
            <img src={section5Img} alt="" />
            <SmallText>
              Jolly Wish List is a fun, interactive, web app that allows kids to interact with Santa and create a list
              all of toys and games he/she wants on their Wish List. Santa takes really good notes and builds his own
              ONLINE REGISTRY PAGE using toys and games available at top retailers. Santa is able to SHARE that registry
              with WHOMEVER wants to know.
            </SmallText>
            <div>
              <Button onClick={handleLetsGetStarted}>Let`s get started</Button>
            </div>
          </div>
          <img src={section5Img} alt="" />
        </div>
      </ContentBox>

      <ContentBox>
        <div className="content-3" style={{ marginBottom: '-4rem', borderBottom: '1px solid #D9D9D9' }}>
          <img src={section3Img1} alt="" />
          <div className="content-3-text">
            <BigText style={{ fontSize: '2.5rem' }}>How it works</BigText>
            <MediumText style={{ fontSize: '1.5rem' }}>
              It is easy for kids and it is free for the adults! <br /> Parents: Click this link for a Parent-Only video
              demonstration
            </MediumText>
            <div style={{ marginBottom: '25px' }}>
              <Button onClick={() => window.open('https://www.youtube.com/@jollywishlist')}>Parent-Only Demo</Button>
            </div>
            <img src={section3Img1} alt="" />
            <SmallText>
              Ready to surprise and delight your child and the many gift-givers in the child’s life? Let’s contact Santa
              Now.
            </SmallText>
            <div style={{ marginBottom: '20px' }}>
              <Button onClick={handleLetsGetStarted}>Let`s get started</Button>
            </div>
          </div>
        </div>
      </ContentBox>
      <Footer />
    </div>
  );
};
