import audio1 from '../../../../images/audios/audio (1).wav';
import audio2 from '../../../../images/audios/audio (2).wav';
import audio3 from '../../../../images/audios/audio (3).wav';
import audio4 from '../../../../images/audios/audio (4).wav';
import audio5 from '../../../../images/audios/audio (5).wav';
import audio6 from '../../../../images/audios/audio (6).wav';
import audio7 from '../../../../images/audios/audio (7).wav';

export const initialAudioFiles = [
  { url: audio1 },
  { url: audio2 },
  { url: audio3 },
  { url: audio4 },
  { url: audio5 },
  { url: audio6 },
  { url: audio7 },
];

export const textAfterProductNoProductSelected = [
  `Let me check with the elves!`,
  `Ho Ho Ho That’s an interesting gift idea!`,
  `That’s a popular choice this year!`,
  `Let me check with the Toy Factory right now!`,
  `That sounds like a lot of fun!`,
  `Wow!  Can I get one too?  Ho Ho Ho!`,
  `I bet Rudolph wants one of those too!`,
];

export const textAfterProductFetched = [
  `That is a great idea! My elves are busy making a lot of those.  Do you want to select your favorite from this list?  Use your finger to tap the screen and PRESS THE GREEN SELECT BUTTON to add it to your Wish List.`,
  `HO HO HO  That is a fun one! Go ahead and tap the “GREEN select” button to add it to your Wish List`,
  `Oh my!  You will have a great time with that! Press the “GREENselect” button to add it to your Wish List`,
  `My elves can make that! Be sure to “select” the ones you want to add it to your Wish List.`,
  `HO HO HO  Fantastic idea! Take a look at these choices and press “select” to add it to your Wish List.`,
  `Very cool!  Even I want one of those! Tap the “GREEN select” to add it to your Wish List`,
  `Mrs. Claus agrees!  Great choice! Press the “select” button to add it to your Wish List`,
  `HO HO HO  I like how you think!  I’ll get the elves started. Go ahead and tap the “select” button to add it to your Wish List`,
  `Alright, alright, alright!  Good choice! Tap “select” to add it to your Wish List.`,
];

export const textAfterProductSelected = [
  `Great selection!  Now, what is the next thing on your wish list?  Speak to me, I’m ALL EARS!  When you are done with your wish list, simply press the “ALL DONE!” green button and our elves will get started on your toys.  What toy is next?`,
  `Perfect!  What is the next toy or game on your Wish List?`,
  `Ho Ho Ho I love it!  What else would you like from jolly old Santa Claus? Tell Santa!`,
  `Nice job!  Can you think of another toy or game that you want?  Remember, when you are done with your wish list, simply press the “ALL DONE!” green button and our elves will get started on your gifts`,
  `Mrs. Claus agrees – that is a great choice!  Is there something else the elves be working on for you?`,
  `Ho Ho Ho  I like it!!  Do you want more toys or games?`,
  `Rudolph like that too! What is the next toy or game on your Wish List?  If you are all finished, just press the “ALL DONE!” button`,
  `Ho Ho Ho  What a great idea!  Tell Santa what else  you would like for the holidays?`,
];

export const textIfDelays = [
  `Feel free to choose your favorite item from this list.  If you need assistance, please ask an adult`,
  `Are you still there?  If you need assistance, please ask an adult`,
  `Please ask the adult to LOG OUT and restart the video chat later today.  Have a great day and talk to you later!!!`,
];
