import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { IconButton } from '@mui/material';
import { Snackbar, Alert } from '@mui/material';
import { LetterPreview } from '../LetterPreview/LetterPreview';
import CloseIcon from '@mui/icons-material/Close';

function LetterPreviewModal({ open, letterContent, openLetterAlert, handleClose }) {
  const style = {
    width: { xs: '90%', sm: '80%', md: '65%' }, // Responsive width: 90% on extra small screens, 70% on small screens, and 50% on medium and up
    maxHeight: '80vh', // Max height to prevent overflow
    overflowY: 'auto', // Enable vertical scroll for long content
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: 2, // Rounded corners for better appearance
    position: 'relative',
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box className="letter-modal" sx={style}>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            sx={{ position: 'absolute', top: 5, right: 15 }}
          >
            <CloseIcon />
          </IconButton>
          <Box sx={{color:'#D70F17', fontWeight: '900', padding: '5px'}}>{`${letterContent?.name}'s Letter Preview`}</Box>
          <LetterPreview letterContent={letterContent} modal />
        </Box>
      </Modal>
      <Snackbar
        open={openLetterAlert}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert elevation={6} variant="filled" onClose={handleClose} severity="error">
          The letter preview is not available in case of voice interaction.
        </Alert>
      </Snackbar>
    </div>
  );
}

export default LetterPreviewModal;
