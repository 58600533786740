import { useState } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import ProductDetailsModal from '../../../../components/ProductDetailsModal';

export default function ProductDisplay({ product, handleSelect }) {
  const [openViewDetails, setOpenViewDetails] = useState(false);

  const handleProductView = () => {
    setOpenViewDetails(true);
  };

  return (
    <>
      <Card
        sx={{
          width: { xs: 250, sm: 320 },
          height: 350, // Fixed height to ensure all cards are the same size
          padding: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            height: 140,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'hidden',
          }}
        >
          <img
            src={product.productImage}
            alt={product.brandName}
            style={{
              maxHeight: '100%',
              maxWidth: '100%',
              objectFit: 'contain',
            }}
          />
        </Box>
        <CardContent sx={{ flexGrow: 1, overflow: 'hidden' }}>
          <Typography gutterBottom variant="h5" component="div">
            {product.brandName}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: 'text.secondary',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 3, // Limits the text to 3 lines
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {product.productDescription}
          </Typography>
        </CardContent>
        <CardActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            onClick={() => handleSelect(product)}
            variant={product.selected ? 'contained' : 'outlined'}
            color={product.selected ? 'success' : 'inherit'}
            sx={{
              flex: 1,
              fontWeight: 'bold',
              marginRight: 1,
              borderRadius: '20px',
              padding: '5px',
              fontSize: { xs: '0.80rem', sm: '1rem' },
              textTransform: 'none',
            }}
          >
            {product.selected ? 'Selected' : 'Select'}
          </Button>
          <Button
            onClick={() => handleProductView(product)}
            variant={'outlined'}
            color={'inherit'}
            sx={{
              flex: 1,
              fontWeight: 'bold',
              borderRadius: '20px',
              padding: '5px',
              fontSize: { xs: '0.80rem', sm: '1rem' },
              textTransform: 'none',
            }}
          >
            View Details
          </Button>
        </CardActions>
      </Card>
      <ProductDetailsModal
        openViewDetails={openViewDetails}
        setOpenViewDetails={setOpenViewDetails}
        product={product}
        handleSelectProduct={handleSelect}
      />
    </>
  );
}
