import React, { useContext, useEffect, useState, useRef } from 'react';
import { Button } from '../../../../components/Button';
import { TemplateItem } from './Item';
import './Container.css';
import { getLetterTemplate } from '../../../../api';
import LoaderContext from '../../../../context/LoaderContext';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import overlayVideo from '../../../../images/step-select-template.mp4';
import { OverlayVideoComponent } from '../../../../components/OverlayVideoComponent';

export const TemplateContainer = ({ setStep, setSelectedTemplate }) => {
  const [templates, setTemplates] = useState([]);
  const { setIsLoading, setMessage } = useContext(LoaderContext);
  const isMobile = useMediaQuery('(max-width:480px)');
  const overlayVideoRef = useRef(null);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    setMessage('Fetching templates...');
    getLetterTemplate().then(res => {
      setIsLoading(false);
      setMessage();
      setTemplates(
        res.data.templateUrl.map((temp, i) => {
          return { id: i + 1, url: temp };
        })
      );
    });
  }, []);

  useEffect(() => {
    const desktopTemmplates = templates.filter(temp => !temp.url?.includes('mobile'));
    const mobileTemplates = templates.filter(temp => temp.url?.includes('mobile'));

    setSelectedTemplate(() => (isMobile ? mobileTemplates[0] : desktopTemmplates[0]));
  }, [templates]);

  useEffect(() => {
    overlayVideoRef.current.play();
    setIsVideoPlaying(true);
    setTimeout(() => {
      setIsVideoPlaying(false);
      if (overlayVideoRef.current) {
        overlayVideoRef.current.pause();
      }
    }, 12000);
  }, []);

  const isParentTemplatesRoute = window.location.pathname === '/parent/templates';

  const [showInfoBox, setShowInfoBox] = useState(isParentTemplatesRoute);

  const handleInfoBoxClose = () => {
    setShowInfoBox(false);
  };

  return (
    <div className="template-container">
      {showInfoBox && (
        <div
          className="info-box"
          style={{ position: 'relative', background: 'black', padding: '20px', fontSize: '18px', color: 'white' }}
        >
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleInfoBoxClose}
            sx={{
              position: 'absolute',
              top: '5px',
              right: '5px',
              background: 'black',
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
          <span>
            <b>Admin</b> <br /> - Following are the available templates for letter writing
          </span>
          <br />
        </div>
      )}
      <br />

      <div style={{ marginBottom: '3rem', width: '100%' }}>
        <p className="page-heading" style={{ fontSize: '', color: '#D70F0F' }}>
          Select a Template
        </p>
        <p className="page-subtext" style={{ fontWeight: '400' }}>
          Choose from a delightful variety of Holiday themed templates. Whether you're a parent or a child, you'll find
          the perfect design to write a heartfelt letter to Santa Claus.
          <br />
          <span>Please Note: Click "Next" if you dont want to apply any template</span>
        </p>
      </div>
      <div className="template-holder" style={{ borderBottom: '1px solid #D9D9D9' }}>
        {templates &&
          templates.length > 0 &&
          templates
            .filter((temp, i) => (isMobile ? temp.url?.includes('mobile') : !temp.url?.includes('mobile')))
            .map((temp, i) => {
              return (
                <TemplateItem
                  url={temp.url}
                  id={temp.id}
                  setStep={setStep}
                  key={temp.url}
                  setSelectedTemplate={setSelectedTemplate}
                  isMobile={isMobile}
                />
              );
            })}
      </div>
      {setStep && (
        <div className="template-buttons">
          <Button onClick={() => setStep(0)}>Previous</Button>
          <Button onClick={() => setStep(4)}>Next</Button>
        </div>
      )}
      <OverlayVideoComponent videoRef={overlayVideoRef} isVideoPlaying={isVideoPlaying} src={overlayVideo} />
    </div>
  );
};
