import './index.css';
import {
  FormControl,
  InputLabel,
  Input,
  FormGroup,
  FormControlLabel,
  Checkbox,
  useMediaQuery,
  Typography,
} from '@mui/material';
import { MuiChipsInput } from 'mui-chips-input';
import { useEffect, useState } from 'react';

export const Letter = ({ selectedTemplate, setLetterDetails, letterDetails }) => {
  const { id } = selectedTemplate;
  const [chips, setChips] = useState([]);
  const isSmallScreen = useMediaQuery('(max-width:480px)');
  const letterPosition = id === 1 ? 'flex-start' : 'center';

  const handleWishlistInput = newChips => {
    setChips(newChips);
  };

  useEffect(() => {
    setLetterDetails({
      ...letterDetails,
      wishlist: chips.join(','),
    });
  }, [chips]);

  const handleChange = e => {
    let { name, value } = e.target;
    if (name === 'age') {
      value = Math.max(0, +value);
    }
    setLetterDetails({
      ...letterDetails,
      [name]: value,
    });
  };

  const handleGratefulFor = e => {
    setLetterDetails({
      ...letterDetails,
      greatful: e.currentTarget.textContent,
    });
  };

  const handleToDo = e => {
    setLetterDetails({
      ...letterDetails,
      willDo: e.currentTarget.textContent,
    });
  };

  const handleDid = e => {
    setLetterDetails({
      ...letterDetails,
      did: e.currentTarget.textContent,
    });
  };

  return (
    <div className="letter" style={{ backgroundImage: `url(${selectedTemplate.url})` }}>
      <div style={{ display: 'flex', justifyContent: letterPosition }}>
        <div className="form">
          <div className="write-letter-page" style={id === 5 ? { color: 'white', minHeight: '1100px' } : {}}>
            <div className="first">
              <div>
                My name
                <FormControl
                  className="letter-input"
                  variant="standard"
                  sx={{
                    width: '280px',
                    margin: '0 1rem',
                    '& .MuiInputBase-input': {
                      color: 'black', // Text color
                      fontWeight: '900',
                    },
                  }}
                  mandatory
                >
                  <InputLabel id="name"></InputLabel>
                  <Input
                    labelId="name"
                    style={{ marginTop: '0' }}
                    id="name"
                    name="name"
                    variant="standard"
                    onChange={handleChange}
                  />
                </FormControl>
              </div>
              <div>
                and I am
                <FormControl
                  className="letter-input"
                  variant="standard"
                  sx={{
                    width: '40px',
                    margin: '0 1rem',
                    '& .MuiInputBase-input': {
                      color: 'black', // Text color
                      fontWeight: '900',
                    },
                  }}
                >
                  <InputLabel id="age"></InputLabel>
                  <Input
                    labelId="age"
                    id="age"
                    type="number"
                    name="age"
                    style={{ marginTop: '0' }}
                    variant="standard"
                    value={letterDetails.age}
                    onChange={handleChange}
                  />
                </FormControl>
                years old.
              </div>
            </div>
            <div className="second">
              This year I have been
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="default"
                      name="haveBeen"
                      onChange={handleChange}
                      value="good"
                      checked={letterDetails.haveBeen === 'good'}
                    />
                  }
                  label={
                    <Typography variant="body1" style={{ fontSize: isSmallScreen ? '0.6rem' : '1rem' }}>
                      Good
                    </Typography>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      color="default"
                      name="haveBeen"
                      onChange={handleChange}
                      value="veryGood"
                      checked={letterDetails.haveBeen === 'veryGood'}
                    />
                  }
                  label={
                    <Typography variant="body1" style={{ fontSize: isSmallScreen ? '0.6rem' : '1rem' }}>
                      Very Good
                    </Typography>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      color="default"
                      name="haveBeen"
                      onChange={handleChange}
                      value="naughty"
                      checked={letterDetails.haveBeen === 'naughty'}
                    />
                  }
                  label={
                    <Typography variant="body1" style={{ fontSize: isSmallScreen ? '0.6rem' : '1rem' }}>
                      Naughty
                    </Typography>
                  }
                />
              </FormGroup>
            </div>

            <div className="third">
              <span>Some of the nice things I did were:</span>
              <div className="text" contentEditable onInput={handleDid}></div>
            </div>
            <div className="third">
              <span>Nice things I’d like to do next year:</span>
              <div className="text" contentEditable onInput={handleToDo}></div>
            </div>
            <div className="third">
              Things I am grateful for:
              <div className="text" contentEditable onInput={handleGratefulFor}></div>
            </div>
            <div className="third">
              My wishlist:{' '}
              <span style={{ fontSize: '14px', color: id === 5 ? 'rgb(201, 195, 195)' : 'gray' }}>
                (Type a specific toy name and press enter to add to the list)
              </span>
              <div>
                <MuiChipsInput
                  className="chips-input"
                  value={chips}
                  onChange={handleWishlistInput}
                  placeholder=""
                  sx={{
                    borderBottom: '0.1px solid rgb(126, 121, 121)',
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: 'none', // Remove the default outline
                    },
                    '& .MuiInputBase-input::placeholder': {
                      color: id === 5 ? 'white' : 'black',
                      fontSize: isSmallScreen ? '11px' : '',
                    },
                    '& .MuiInputBase-input': {
                      fontWeight: '900',
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
