import './Item.css';

export const TemplateItem = ({ url, setStep, id, setSelectedTemplate, isMobile }) => {
  return (
    <div
      className="template-item"
      style={isMobile ? { flexGrow: '1' } : {}}
      onClick={() => {
        if (setSelectedTemplate) {
          setSelectedTemplate({ id, url });
          return setStep(4);
        }
      }}
    >
      <img src={url} alt="" />
    </div>
  );
};
