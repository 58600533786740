// Importing necessary React and Material-UI components
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { AppHeader } from '../../components/AppHeader';
import { Stepper, Step, StepLabel, IconButton } from '@mui/material';
import { SelectProfile } from './components/SelectProfile';
import { TemplateContainer } from './components/TemplateSelector/Container';
import { WriteLetter } from './components/WriteLetter';
import { KidSuccess } from './components/KidSuccess/KidSuccess';
import CloseIcon from '@mui/icons-material/Close';
import { SelectPath } from './components/SelectPath';
import SantaTalks from './components/SantaTalks';

// Importing component-specific styles
import './index.css';

// Array defining the steps of the process
const steps = ["Select a child's profile", 'Choose a template', 'Write a letter'];

/**
 * Functional component representing the Kids section.
 * @param {Object} props - Component props
 * @param {boolean} props.loggedIn - Flag indicating if the user is logged in
 * @returns {JSX.Element} - Rendered Kids component
 */
export const Kids = ({ loggedIn }) => {
  // State variables to manage the active step, selected template, selected profile, and location
  const [activeStep, setActiveStep] = useState(0);
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const [selectedProfile, setSelectedProfile] = useState({});
  const { pathname } = useLocation();
  const [turnOffMicrophoneInfo, setTurnOffMicrophoneInfo] = useState(false);

  // State variable and handler for displaying/hiding information box
  const [showInfoBox, setShowInfoBox] = useState(true);
  const handleInfoBoxClose = () => {
    setShowInfoBox(false);
  };

  /**
   * Helper function to get step-specific messages based on the active step and path.
   * @returns {string} - Step-specific message
   */
  const getStepMessage = () => {
    switch (activeStep) {
      case 0:
        return pathname === '/kid' ? (
          <>
            <b>
              <u>STEP 2: SELECT A CHILD (PARENTS & CHILDREN) </u>
            </b>{' '}
            <br></br>
            <br></br> Dive into the fun! Tap on the child’s profile to start creating a special Wish List and share with
            Santa Claus!
          </>
        ) : (
          <>
            <b>
              <u>STEP 2: PARENTS!</u>
            </b>{' '}
            <br></br>
            <br></br> Select <b>Child Profile</b> when you and your child are ready to start composing a letter to
            Santa.
          </>
        );
      case 3:
        return pathname === '/kid' ? (
          <>
            <b>
              <u>STEP 3: CHOOSE YOUR WISH LIST LETTER DESIGN (PARENTS & CHILDREN)</u>
            </b>{' '}
            <br></br>
            <br></br> Pick a merry template for your message to Santa Claus!
          </>
        ) : (
          <>
            <b>
              <u> STEP 3: PARENTS!</u>
            </b>{' '}
            <br></br>
            <br></br> Pick a merry template for your kids' message to Santa Claus.
          </>
        );
      case 4:
        return pathname === '/kid' ? (
          <>
            <b>
              <u>STEP 4: TYPE YOUR WISH LIST TO SANTA! (PARENTS & CHILDREN)</u>
            </b>{' '}
            <ul>
              <li>
                Immerse yourself in the magic of Holiday Season as you compose. Let your imagination run wild as you
                reflect on the past year, express gratitude, and eagerly share your holiday wishes, bringing the holiday
                spirit to life.{' '}
              </li>
              <li>
                <b>REMEMBER </b>Santa is best at getting toys and games that you can find in the stores. No ponies or
                puppies!{' '}
              </li>
              <li>
                When finished, click <b>Send to Santa </b> button.
              </li>
            </ul>
          </>
        ) : (
          <>
            <b>
              <u>STEP 4: PARENTS!</u>
            </b>{' '}
            <br></br>
            <br></br> Immerse yourself in the magic of Holiday Season as you compose. Let your imagination run wild as
            you reflect on the past year, express gratitude, and eagerly share your holiday wishes, bringing the holiday
            spirit to life. When finished, click "Send to Santa" button.
          </>
        );
      case 5:
        return pathname === '/kid' ? (
          <>
            <p>
              {' '}
              <b>
                <u>STEP 5: CONGRATULATIONS – YOU EMAILED SANTA CLAUS! (PARENTS & CHILDREN)</u>
              </b>
            </p>
            <ul>
              <li>Your wish has been shared successfully!</li>
              <li>Take a break for now, log out, and keep an eye on your inbox to see if Santa responds tomorrow.</li>
              <li>PARENTS: Feel free to log back in to the site at any time.</li>
            </ul>
          </>
        ) : (
          <>
            <p>
              {' '}
              <b>
                <u>STEP 5:Congratulations Parents!</u>
              </b>
            </p>
            <p>
              Your child's wish has been sent successfully! Now, feel free to update and share their wishlist with
              friends and family.
            </p>
          </>
        );
      default:
        return '';
    }
  };

  /**
   * Helper function to get the style for the close button based on the path.
   * @returns {Object} - Style object for close button
   */
  const getCloseButtonStyle = () => {
    return pathname === '/kid' ? { top: '80px' } : { top: '15px' };
  };

  // Rendering the Kids component
  return (
    <>
      {!loggedIn && <AppHeader showParentSignIn={true} loggedIn={loggedIn} />}
      <div className="kids" style={pathname === '/kid' ? { padding: '1.5rem' } : {}}>
        {showInfoBox && ![1, 2].includes(activeStep) && (
          <div
            className="message-box"
            style={{
              position: 'relative',
              background: '#005424',
              padding: '20px',
              fontSize: '18px',
              color: '#ffffff',
            }}
          >
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleInfoBoxClose}
              sx={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                // background: 'black',
                zIndex: 1,
                // ...getCloseButtonStyle(),
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
            <span>{getStepMessage()}</span>
          </div>
        )}

        {/* Content Section */}
        <div className="kids-content">
          {/* {![1, 2].includes(activeStep) && (
          <div className="stepper-container" style={{ borderBottom: '1px solid #DDDDDD' }}>
            <Stepper activeStep={[3, 4].includes(activeStep) ? activeStep - 1 : activeStep} nonLinear={false}>
              {steps.map((label, index) => (
                <Step key={label} index={index}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </div>
        )} */}

          {/* Rendering different components based on the active step */}
          {activeStep === 0 && <SelectProfile setStep={setActiveStep} setSelectedProfile={setSelectedProfile} />}
          {activeStep === 1 && <SelectPath setStep={setActiveStep} />}
          {activeStep === 2 && (
            <SantaTalks
              setStep={setActiveStep}
              selectedProfile={selectedProfile}
              setTurnOffMicrophoneInfo={setTurnOffMicrophoneInfo}
            />
          )}
          {activeStep === 3 && <TemplateContainer setStep={setActiveStep} setSelectedTemplate={setSelectedTemplate} />}
          {activeStep === 4 && (
            <WriteLetter
              setStep={setActiveStep}
              selectedTemplate={selectedTemplate}
              selectedProfile={selectedProfile}
            />
          )}
          {activeStep > 4 && (
            <KidSuccess
              setStep={setActiveStep}
              selectedProfile={selectedProfile}
              setTurnOffMicrophoneInfo={setTurnOffMicrophoneInfo}
              turnOffMicrophoneInfo={turnOffMicrophoneInfo}
            />
          )}
        </div>
      </div>
    </>
  );
};
