import React, { useState } from 'react';
import { useUser } from '../../../../UserContext';
import Cookies from 'js-cookie';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { Box, Typography, Popover, Button } from '@mui/material';
import { deactivateAccount } from '../../../../api';
import AccordionComponent from '../../../../components/AccordionComponent';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../../../firebase';
import './style.css';

export function Settings() {
  const { setUserData, userId } = useUser();

  const [anchorEl, setAnchorEl] = useState(null);
  const [expanded, setExpanded] = useState({
    'deactivate-account': true,
  });

  const handleSectionExpansion = type => {
    setExpanded(prev => {
      return { ...prev, [type]: !prev[type] };
    });
  };

  // Function to handle popup open
  const handleOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  // Function to handle popup close
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Function to handle confirmation action
  const handleConfirm = () => {
    console.log('Confirmed!'); // Add your logic here
    handleClose();
    deactivateAccount({ userId }).then(() => {
      logEvent(analytics, 'Account Deactivated', { userId });
      logOutHandler();
    });
  };

  const open = Boolean(anchorEl);
  const id = open ? 'settings-popover' : undefined;

  const logOutHandler = () => {
    setUserData({});
    localStorage.removeItem('latestUserId');
    localStorage.removeItem('user');
    Cookies.remove('userId');
  };

  const renderDeactivateAccountDeatils = () => {
    return (
      <div className="deactivate-conatiner">
        <div>
          Note: Once your account is deleted or deactivated, it cannot be recovered, and all data related to your profile, kids, and wishlists will be permanently removed. This action is irreversible.
        </div>
        <span>
          <Button
            onClick={handleOpen}
            sx={{
              backgroundColor: '#B51B01',
              color: 'white',
              textTransform: 'none', // Prevent uppercase transformation
              '&:hover': {
                backgroundColor: '#B51B01', // Maintain the same background color on hover
              },
            }}
          >
            Deactivate Account
          </Button>
        </span>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
        >
          <Box padding={2}>
            <Typography>Are you sure you want to deactivate your account?</Typography>
            <Box marginTop={2} display="flex" justifyContent="space-between">
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleConfirm} color="success" variant="contained">
                Confirm
              </Button>
            </Box>
          </Box>
        </Popover>
      </div>
    );
  };

  return (
    <div className="settings-conatiner">
      <div className="settings-label">Settings</div>
      <div>
        <AccordionComponent
          title="Deactivate Account"
          renderContent={renderDeactivateAccountDeatils}
          onChange={() => handleSectionExpansion('deactivate-account')}
          isExpanded={expanded['deactivate-account']}
        />
      </div>
    </div>
  );
}
