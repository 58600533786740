import React, { useContext, useEffect, useMemo, useState } from 'react';

import { Stepper, Step, StepLabel, IconButton } from '@mui/material';
import { VerifyProducts } from '../VerifyProducts';
import { FinaliseProducts } from '../FinaliseProducts';
import { ShareList } from '../ShareList';
import { ParentSuccess } from '../ParentSuccess/ParentSuccess';
import { getWishlist } from '../../../../../../api';
import { useUser } from '../../../../../../UserContext';
import LoaderContext from '../../../../../../context/LoaderContext';
import CloseIcon from '@mui/icons-material/Close';
import wishlistImage from '../../../../../../images/wishlist.png';
import { Button } from '../../../../../../components/Button';
import { useNavigate } from 'react-router-dom';

const steps = ['Verify Product List', 'Finalise Products', 'Share List'];

export const KidProfile = ({ activeStep, setActiveStep, wishlist, isWishlistLoaded, isChildDataLoaded, ...props }) => {
  const [child, setChild] = useState();
  const { userData } = useUser();
  const [link, setLink] = useState();

  const [products, setProducts] = useState();
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [newItems, setNewItems] = useState([]);
  const [shareViaEmail, setShareViaEmail] = useState(true);
  const navigate = useNavigate();

  const capitalize = name => {
    let finalName = '';
    name.split(' ').forEach((word, i) => {
      if (i == 0) {
        finalName += word.charAt(0).toUpperCase() + word.slice(1);
        finalName += ' ';
      } else {
        finalName += word;
        if (i !== name.split(' ').length - 1) {
          finalName += ' ';
        }
      }
    });
    return finalName;
  };

  useEffect(() => {
    const child = wishlist.find(wish => wish.childid === props.child.childid);
    setChild(child);

    if (child && Object.keys(child).length) {
      setProducts(
        child.wishlistitem.map((item, i) => {
          return {
            id: i + 1,
            name: capitalize(item),
          };
        })
      );
    } else {
      setProducts([]);
    }
  }, [wishlist, activeStep]);

  const child1 = useMemo(() => {
    return wishlist.find(wish => wish.childid === props.child.childid);
  }, [wishlist]);

  if (isChildDataLoaded && isWishlistLoaded && !child1?.wishlistitem?.length) {
    // return <div className="no-wishlist-message">Wishlist is not available for {props.child.childname}! </div>

    return (
      <div className="fetch-data" style={{ marginTop: 0 }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <img src={wishlistImage} alt="Child Wishlist not available" style={{ width: '450px', height: 'auto' }} />
          <Button onClick={() => navigate('/parent/kid')} style={{ width: '40%', alignSelf: 'center' }}>
            Add Wishlist
          </Button>
        </div>
      </div>
    );
  }

  return (
    <>
      <br />
      {products && products.length > 0 && (
        <div>
          {/* {activeStep <= 2 && (
            <div className="stepper-container">
              <Stepper activeStep={activeStep} nonLinear={false}>
                {steps.map((label, index) => {
                  return (
                    <Step key={label} index={index}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
            </div>
          )} */}
          {activeStep === 0 && (
            <VerifyProducts
              child={child}
              products={products}
              setProducts={setProducts}
              setStep={setActiveStep}
              setNewItems={setNewItems}
              newItems={newItems}
              wishlistDetails={wishlist}
            />
          )}
          {activeStep === 1 && (
            <FinaliseProducts
              products={products}
              newItems={newItems}
              setStep={setActiveStep}
              setSelectedProducts={setSelectedProducts}
              child={child}
            />
          )}
          {activeStep === 2 && (
            <ShareList
              child={child}
              userData={userData}
              products={selectedProducts}
              setStep={setActiveStep}
              setLink={setLink}
              shareViaEmail={shareViaEmail}
              setShareViaEmail={setShareViaEmail}
            />
          )}
          {activeStep > 2 && <ParentSuccess link={link} shareViaEmail={shareViaEmail} />}
        </div>
      )}
    </>
  );
};
