// Importing necessary React components, styles, and images
import { useState } from 'react';
import { Grid, FormControl, InputLabel, Input } from '@mui/material';
import { Button } from '../../../components/Button';
import SignUpImage from '../../../images/Register_Img.png';
import Close from '../../../images/close.svg';
import Left from '../../../images/arrow-left.svg';
import { forgotPassword } from '../../../api';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../../firebase';

/**
 * ForgotPassword Component
 *
 * @param {Object} props - React component props.
 * @param {Function} props.handleClose - Function to handle closing the ForgotPassword modal.
 * @param {Function} props.handleSignin - Function to handle navigating back to the login page.
 * @returns {ReactNode} - Rendered component for the ForgotPassword feature.
 */
export const ForgotPassword = ({ handleClose, handleSignin }) => {
  // State to manage email input and email sent status
  const [email, setEmail] = useState();
  const [isEmailSent, setIsEmailSent] = useState(false);

  // Function to handle form submission
  const handleSubmit = e => {
    e.preventDefault();
    // Call API function to request password reset
    forgotPassword({ emailId: email }).then(() => {
      logEvent(analytics, 'Forgot Password', { emailId: email });
    });

    setEmail('');
    setIsEmailSent(true);
  };

  return (
    <div className="register">
      {/* Image section */}
      <img src={SignUpImage} alt="" />

      {/* Form section */}
      <div className="login-signup-container">
        <div className="forms-heading">Forgot Password</div>
        <div className="forms-subheading">No worries, we’ll send you the reset instructions</div>
        <div className="forms-content">
          {/* Close button */}
          <div className="close-btn" onClick={() => handleClose(false)}>
            <img src={Close} alt="close" />
          </div>

          {/* Display success message if email is sent */}
          <div style={{ color: 'green' }} className="error-message">
            {isEmailSent && <p>Password has been sent to your registered email!</p>}
          </div>

          {/* Forgot Password form */}
          <form onSubmit={handleSubmit}>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                {/* Email input field */}
                <FormControl variant="standard" sx={{ width: '100%', margin: '1rem' }} required>
                  <InputLabel id="email">Enter Registered Email</InputLabel>
                  <Input
                    labelId="email"
                    id="email"
                    variant="standard"
                    value={email}
                    required
                    onChange={e => setEmail(e.target.value)}
                  />
                </FormControl>
              </Grid>

              {/* Submit button */}
              <Button variant="fullWidth" style={{ marginTop: '8px' }} type="submit">
                Submit
              </Button>

              {/* Back to login link */}
              <div
                className="forgot-password-link"
                onClick={() => {
                  handleClose(false);
                  handleSignin(true);
                }}
                style={{
                  width: '100%',
                  margin: 'auto',
                  marginTop: '1rem',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '5px',
                  justifyContent: 'center',
                }}
              >
                <img src={Left} alt="" style={{ maxHeight: '18px', width: 'auto' }} />
                <p>Back To Login</p>
              </div>
            </Grid>
          </form>
        </div>
      </div>
    </div>
  );
};
