import { useMemo, useContext, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Button } from '../../components/Button';
import { ProductCard } from '../parent/components/Wishlist/components/ProductCard/ProductCard';
import { AppHeader } from '../../components/AppHeader';
import { getOrder, getChildInfo, acknowledgeProduct } from '../../api';
import { useState, useEffect } from 'react';
import LoaderContext from '../../context/LoaderContext';
import { AlignHorizontalCenter } from '@mui/icons-material';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../firebase';
import overlayVideo from '../../images/step-giftgiver.mp4';
import { OverlayVideoComponent } from '../../components/OverlayVideoComponent';

const useQuery = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};

export const RegistryList = ({ setActiveStep, isBackButtonVisible }) => {
  // const history = useNavigate();
  const query = useQuery();
  const userid = query.get('userid');
  const childid = query.get('childid');
  const [products, setProducts] = useState();
  const [childDetails, setChildDetails] = useState({});
  const { setIsLoading } = useContext(LoaderContext);
  const overlayVideoRef = useRef(null);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    if (userid) {
      getOrder({ userid: userid, childid: childid }).then(res => {
        setIsLoading(false);
        setProducts(res.data.result);
      });
      getChildInfo({ userId: userid }).then(res => {
        const child = res.data.childInfo.find(child => child.childid === childid);
        setChildDetails(child);
      });
    }
  }, [userid, childid, setIsLoading]);

  useEffect(() => {
    overlayVideoRef.current.play();
    setIsVideoPlaying(true);
    setTimeout(() => {
      setIsVideoPlaying(false);
      if (overlayVideoRef.current) {
        overlayVideoRef.current.pause();
      }
    }, 44000);
  }, []);

  const handleAcknowledgement = product => {
    const payload = {
      orderid: products.orderid,
      ackItems: [
        {
          itemId: product.itemId,
          isOrdered: true,
        },
      ],
    };
    if (!product.isOrdered) {
      acknowledgeProduct(payload).then(() => {
        getOrder({ userid: userid, childid: childid }).then(res => {
          setIsLoading(false);
          setProducts(res.data.result);
          logEvent(analytics, 'Product Acknowledged', { details: payload });
        });
      });
    }
  };

  return (
    <>
      {userid && childid && childDetails?.childname && (
        <div>
          <div className="giftgiver registry-list">
            <div className="heading">{childDetails.childname}&apos;s Wish list</div>
            <div className="subtext">
              Explore the items below and choose a present that brings joy and excitement to your loved one on this
              Christmas.
            </div>
            <div className="product-list">
              {products &&
                products.orderlist.length > 0 &&
                products.orderlist.map((prod, index) => {
                  return (
                    <>
                      {prod.productList.length > 0 && (
                        <div className="product-list-item" key={index}>
                          <div className="item-heading">
                            Gift #{index + 1}: <span>{prod.itemName}</span>
                          </div>
                          <div
                            className="item-products"
                            style={{
                              justifyContent: 'flex-start',
                              flexWrap: 'wrap',
                            }}
                          >
                            {prod.productList.map((product, key) => {
                              return (
                                <ProductCard
                                  key={key}
                                  product={product}
                                  variant="registry"
                                  handleAcknowledgement={handleAcknowledgement}
                                />
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </>
                  );
                })}
            </div>
            {isBackButtonVisible && (
              <div className="cta">
                <Button onClick={() => setActiveStep(0)}>Back</Button>
              </div>
            )}
          </div>
        </div>
      )}
      <div className="registrylist-footer">
        <p
          style={{
            margin: 0, // Remove default margin for alignment
            fontFamily: 'figtree',
            fontSize: '20px',
            maxWidth: '80%',
            padding: '10px',
          }}
        >
          <b>GIFT GIVERS</b> - please don’t forget to mark off the items you purchased for your loved one. Click the{' '}
          <b>Acknowledge Purchase</b> button next to the item so that the next gift givers does not get the same gift.
          Thank you!
        </p>
        <Button
          onClick={() => {
            window.location.href =
              'mailto:info@jollywishlist.com?subject=Feedback&body=Please enter your feedback here.';
          }}
          style={{
            backgroundColor: '#AC0101',
            color: 'white',
            padding: '5px 15px',
            border: 'none',
            borderRadius: '20px',
            cursor: 'pointer',
            fontSize: '20px',
            margin: '15px',
          }}
        >
          Submit Feedback
        </Button>
      </div>
      <OverlayVideoComponent videoRef={overlayVideoRef} isVideoPlaying={isVideoPlaying} src={overlayVideo} />
    </>
  );
};
