import { useState } from 'react';
import { ProductCard } from '../components/ProductCard/ProductCard';
import { Button } from '../../../../../components/Button';
import { createOrder } from '../../../../../api';
import Switch from '@mui/material/Switch';
import { useMediaQuery } from '@mui/material';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../../../../firebase';

export const ShareList = ({ child, userData, products, setStep, setLink, shareViaEmail, setShareViaEmail }) => {
  const [message, seMessage] = useState('');
  const [emails, setEmails] = useState();
  const [isEmail, setIsEmail] = useState(true);

  const handleOrder = () => {
    if (!emails && shareViaEmail) {
      setIsEmail(false);
      return;
    }
    const payload = {
      userid: userData.userInfo.userid,
      childid: child.childid,
      emailmessage: message,
      emaillist: (emails || '').split(',').map(str => str.trim()),
      productlist: products,
    };
    createOrder(payload).then((res) => {
      if (res.data && res.data.giverUrl) {
        setLink(res.data.giverUrl);
      }
      setStep(3);
      logEvent(analytics, 'Order Created', { details: payload });
    });
  };

  const handleToggle = () => {
    setShareViaEmail(!shareViaEmail);
  };

  // Place buttonCaption assignment outside JSX
  const buttonCaption = shareViaEmail ? 'Share Wishlist via Email' : 'Generate Link & Share Wishlist';
  const smallScreen = useMediaQuery('(max-width:480px)');

  return (
    <>
      <div className="sharelist" style={{ marginBottom: '2rem' }}>
        <div className="page-subheading" style={{ marginBottom: '12px' }}>
          Final Gift List:
        </div>
        <div className="page-subtext">
          Share the below kids registry list with friends, family, or anyone else you want to let them know about it.
        </div>
        <div className="sharelist-products">
          {products &&
            products.map(prod => {
              return prod.productList.map((product, index) => {
                return <ProductCard key={index} product={product} shareList />;
              });
            })}
        </div>
        <div className="sharelist-separator" />
      </div>
      <div className='share'>
        <span>Share a Link</span>
        <span>
          <Switch
            checked={shareViaEmail}
            onChange={handleToggle}
            color="primary"
          />
          <span>Share via Email</span>
        </span>
      </div>
      {shareViaEmail && (
        <div className="share-form">
          <div className="page-subheading">Share it with gift givers:</div>
          <div className="">
            <textarea rows="4" style={smallScreen ? {width: '90%'} : {}} placeholder="Add your custom message to the gift giver.  Example: “Thank you for your generosity this holiday season.  Johnny is going to love these gifts!" onChange={e => seMessage(e.target.value)} />
            <textarea rows="2" style={smallScreen ? {width: '90%'} : {}} placeholder="Enter gift giver’s email address here. " onChange={e => setEmails(e.target.value)} />
            {!isEmail && <div style={{ color: 'red' }}>Email is mandatory!</div>}
            <div className="">
              <strong>Note:</strong> Add commas for multiple email addresses.  Example: janedoe@jollywishlist.com, johndoe@jollywishlist.com
            </div>
          </div>
        </div>
      )}
      <br/>
      <Button
        variant="primary"
        style={{ margin: "0 0 1rem 0", float: "left" }}
        onClick={() => setStep((prevStep) => prevStep - 1)}
      >
        Previous
      </Button>
      
      <Button
        variant="primary"
        style={{ margin: '0 0 1rem 0', float: smallScreen ? "left" : "right" }}
        onClick={handleOrder}
      >
        {buttonCaption}
      </Button>
      
    </>
  );
};
