import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useLocation } from 'react-router-dom';

import './KidSuccess.css';
import check from '../../../../images/check.png';
import KidsSanta from '../../../../images/KidsSanta.png';
import SucessAnimation from '../../../../components/SucessAnimation';
import bgAudio from '../../../../images/audios/kids_page_music.mp3';

import { useUser } from '../../../../UserContext';
import Cookies from 'js-cookie';
import { Snackbar, Alert } from '@mui/material';

/**
 * Component displaying success message after sending a letter.
 * @param {Object} props - Component props
 * @param {function} props.setStep - Function to set the active step
 * @param {Object} props.selectedProfile - Selected child's profile
 * @returns {JSX.Element} - Rendered KidSuccess component
 */
export const KidSuccess = ({ setStep, selectedProfile, setTurnOffMicrophoneInfo, turnOffMicrophoneInfo }) => {
  const { setUserData } = useUser();
  const { pathname } = useLocation();
  const audioRef = useRef(null);

  const handleLogout = () => {
    setUserData({});
    localStorage.removeItem('latestUserId');
    localStorage.removeItem('user');
    Cookies.remove('userId');
  };

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = 0.3;
    }
  }, []);

  return (
    <>
      <SucessAnimation />
      <audio ref={audioRef} src={bgAudio} autoPlay loop />
      <div className="kids-success">
        <img src={check} alt="" />
        <p>
          Congratulations {selectedProfile.name}, <br />
          Your wish has been sent to Santa Claus!
        </p>
        <img src={KidsSanta} style={{ width: '20rem', marginTop: '0rem' }} alt="" />
        <div className="template-buttons" style={{ marginBottom: '2.5rem', marginTop: '0.5rem' }}>
          <Button
            variant="contained"
            onClick={() => {
              setStep(0);
              setTurnOffMicrophoneInfo(false);
            }}
            style={{
              backgroundColor: '#015523',
              color: 'white',
              borderRadius: '20px',
              textTransform: 'capitalize',
              fontSize: '1.0rem',
              marginRight: '10px',
            }}
          >
            Send Another Wish
          </Button>

          {pathname === '/kid' && ( // Conditionally render the logout button only for /kid route
            <Button
              variant="contained"
              onClick={handleLogout}
              style={{
                backgroundColor: '#ba1509',
                color: 'white',
                borderRadius: '20px',
                textTransform: 'capitalize',
                fontSize: '1.0rem',
              }}
            >
              Logout
            </Button>
          )}
        </div>
      </div>
      <Snackbar
        open={turnOffMicrophoneInfo}
        autoHideDuration={4000}
        onClose={() => setTurnOffMicrophoneInfo(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert elevation={6} variant="filled" onClose={() => setTurnOffMicrophoneInfo(false)} severity="error">
          Please turn off your Microphone
        </Alert>
      </Snackbar>
    </>
  );
};
