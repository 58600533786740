import React from 'react';
import './style.css';

export function OverlayVideoComponent({ videoRef, isVideoPlaying, src }) {
  return (
    <div className="overlay-video" style={{ display: isVideoPlaying ? 'block' : 'none' }}>
      <video ref={videoRef} src={src} />
    </div>
  );
}
