import { Button } from '../../../../../components/Button';
import { ProductList } from './ProductList/ProductList';
import { updateWishlist } from '../../../../../api';
import { useContext } from 'react';
import LoaderContext from '../../../../../context/LoaderContext';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../../../../firebase';

export const VerifyProducts = ({ child, setStep, products, setProducts, newItems, setNewItems, wishlistDetails }) => {
  const { setIsLoading, setMessage } = useContext(LoaderContext);
  const handleSubmitList = () => {
    setIsLoading(true);
    setMessage(`Updating wishlist...`);
    updateWishlist({
      wishlistid: child.wishlistid,
      wishlistitem: [...products.map(prod => prod.name), ...newItems],
    }).then(() => {
      setIsLoading(false);
      setMessage();
      setStep(1);
      logEvent(analytics, 'Wishlist Updated', {
        details: { wishlistid: child.wishlistid, wishlistitem: [...products.map(prod => prod.name), ...newItems] },
      });
    });
  };

  return (
    <>
      <ProductList
        child={child}
        products={products}
        setProducts={setProducts}
        setNewItems={setNewItems}
        newItems={newItems}
        wishlistDetails={wishlistDetails}
      />
      <Button
        variant="primary"
        style={{ float: 'right' }}
        onClick={() => {
          handleSubmitList();
        }}
      >
        Next
      </Button>
    </>
  );
};
