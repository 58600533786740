import React from 'react';
import './Loader.css'; // Assuming the CSS is in a separate file named Loader.css

const Loader = () => {
  return (
    <div className="loader"></div>
  );
};

export default Loader;
