// Importing necessary styles and components
import './App.css';
import { HomePage } from './pages/home';
import { Kids } from './pages/kids';
import { Route, BrowserRouter, Routes, Navigate } from 'react-router-dom';
import { ProtectedRoute } from './routes/ProtectedRoute';
import { Parent } from './pages/parent';
import { Tnc } from './pages/parent/components/Tnc';
import { PrivacyPolicy } from './pages/parent/components/PrivacyPolicy';
import { Profile } from './pages/parent/components/Profile';
import { Wishlist } from './pages/parent/components/Wishlist';
import { Faqs } from './pages/parent/components/Faqs';
import { TemplateGallery } from './pages/parent/components/TemplateGallery';
import { GiftGiver } from './pages/giftgiver';
import { RegistryList } from './pages/giftgiver/RegistryList';
import { UserProvider } from './UserContext';
import { Register } from './components/HomeHeader/RightNav/Register';
import { Login } from '@mui/icons-material';
import { useUser } from './UserContext';
import LoaderProvider from './context/LoaderProvider';
import FullScreenLoader from './components/FullScreenLoader';
import { analytics } from './firebase';
import { useEffect } from 'react';
import ScrollToTop from './components/ScrollToTop';
import { Settings } from './pages/parent/components/Settings';

// Main App Component
function App() {
  // Using the UserContext to get user data
  const { userData, userId } = useUser();

  useEffect(() => {
    if (analytics) {
      console.log('Firebase Analytics initialized');
    }
  }, []);

  return (
    // Wrapping the app with LoaderProvider for loading state management
    <LoaderProvider>
      {/* Setting up BrowserRouter for navigation */}
      <BrowserRouter>
        {/* Defining routes using React Router */}
        <ScrollToTop />
        <Routes>
          {/* Home route, redirects to kid page if user is logged in */}
          <Route path="/" element={userId ? <Navigate to="/kid" /> : <HomePage />} />

          {/* Kid route, redirects to home if user is not logged in */}
          <Route path="/kid" element={userId ? <Kids loggedIn={false} /> : <Navigate to="/" />} />

          {/* Protected routes for authenticated users */}
          <Route element={<ProtectedRoute isAllowed />}>
            <Route path="register" element={<Register />} />
            <Route path="login" element={<Login />} />

            {/* Parent route, redirects to home if user is not authenticated */}
            <Route path="/parent" element={<Navigate to="/parent/profile" replace />} />
            <Route path="parent" element={userData && userData.isAuthenticated ? <Parent /> : <Navigate to="/" />}>
              <Route index path="profile" element={<Profile />} />
              <Route path="wishlist" element={<Wishlist />} />
              <Route path="kid" element={<Kids loggedIn={true} />} />
              <Route path="audio" element={<Kids loggedIn={true} />} />
              <Route path="faqs" element={<Faqs />} />
              <Route path="templates" element={<TemplateGallery />} />
              <Route path="terms-and-conditions" element={<Tnc />} />
              <Route path="privacy-policy" element={<PrivacyPolicy />} />
              <Route path="settings" element={<Settings />} />
            </Route>

            {/* GiftGiver route */}
            <Route path="giftgiver" element={<GiftGiver />} />
            <Route path="registry-list" element={<RegistryList />} />
          </Route>

          {/* Additional routes for terms and conditions and privacy policy */}
          <Route path="terms-and-conditions" element={<Tnc homePage />} />
          <Route path="privacy-policy" element={<PrivacyPolicy homePage />} />
          <Route path="faqs" element={<Faqs homePage />} />
        </Routes>
      </BrowserRouter>

      {/* FullScreenLoader component displayed during loading */}
      <FullScreenLoader />
    </LoaderProvider>
  );
}

// Exporting the main App component
export default App;
