import { useState, useEffect, useContext } from 'react';

import { Box } from '@mui/material';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useNavigate } from 'react-router-dom';

import './index.css';
import { KidProfile } from './components/KidProfile/KidProfile';
import { getChildInfo, getWishlist } from '../../../../api';
import { useUser } from '../../../../UserContext';
import LoaderContext from '../../../../context/LoaderContext';
import AddKidsFirst from '../../../../images/AddKidsFirst.png';
import { Stepper, Step, StepLabel, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Button } from '../../../../components/Button';

export const Wishlist = () => {
  // const [productList, seProductList] = useState([]);
  const [childInfo, setChildInfo] = useState([]);
  const [value, setValue] = useState(1);
  const { userData } = useUser();
  // eslint-disable-next-line no-undef
  const { setIsLoading, setMessage } = useContext(LoaderContext);
  const [isChildDataLoaded, setIsChildDataLoaded] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [showInfoBox, setShowInfoBox] = useState(true);
  const [wishlist, setWishlist] = useState([]);
  const [isWishlistLoaded, setIsWishlistLoaded] = useState(false);
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setActiveStep(0);
  };

  const handleInfoBoxClose = () => {
    setShowInfoBox(false);
  };

  useEffect(() => {
    setIsLoading(true);
    setIsChildDataLoaded(false);
    setIsWishlistLoaded(false);
    setMessage('Fetching child wishlist data, please wait.');
    getChildInfo({ userId: userData.userInfo.userid }).then(res => {
      setIsLoading(false);
      setIsChildDataLoaded(true);
      setChildInfo(res.data.childInfo);
    });

    getWishlist({ userid: userData.userInfo.userid }).then(res => {
      setIsLoading(false);
      setIsWishlistLoaded(true);
      setMessage();
      setWishlist(res.data.wishList);
    });
    return () => {
      setIsLoading(false);
      setMessage();
    };
  }, []);

  const getStepMessage = step => {
    switch (step) {
      case 0:
        return (
          <>
            <b>
              <u>STEP 6: REVIEW THE CHILD’S WISH LIST!(PARENTS ONLY) </u>
            </b>{' '}
            <br />
            <ul>
              <li>
                This page provides an organized and convenient way to keep track of your child’s favorite items and
                share them with other gift givers among your family and friends.
              </li>
              <li>
                If you want to add an item to the list, simply click <b>"Add Item"</b> and fill out the text box.
              </li>
              <li>
                If you want to delete an item, click on the <b>"Edit List"</b> button to remove an item. Click{' '}
                <b>"Save & Submit"</b> button and allow the Jolly Wish List platform to match your information with the
                retailer's website.
              </li>
            </ul>
            Note: you can navigate between children by clicking on their names listed below.{' '}
          </>
        );

      case 1:
        return (
          <>
            <b>
              <u>SELECT THE FINAL TOY WISH LIST (PARENTS ONLY)</u>
            </b>
            <ul>
              <li>
                Congratulations! The platform used the child’s Wish List to map against the available items on the
                Walmart.com site.
              </li>
              <li>
                <b>Now, you get to choose the proper gift idea!</b>
              </li>
              <li>
                For each item, scroll through using the left & right arrows to find the item you think your child wants.
              </li>
              <li>
                Click the <b>"Select button" </b> and that item will be added to the list. You may select multiple items
                from a single line.
              </li>
              <li>
                If you want to add another gift idea, <b>simply add it into the text box</b> and click the{' '}
                <b>“Search” </b> button and it will be added at the bottom of the list.
              </li>
              <li>
                To remove an item, simply <b>deselect </b>the product item.
              </li>
              <li>
                When all done, click <b> "Next" </b>
              </li>
            </ul>
          </>
        );

      case 2:
        return (
          <>
            <b>
              <u>STEP 8: SEND YOUR LIST TO YOUR FAMILY & FRIENDS (PARENTS ONLY)</u>
            </b>
            <br />
            <ul>
              <li>
                You are almost done! Add a <b>personalized email greeting</b> in the text box provided. And then, your
                registry is ready to be sent!
              </li>
              <li>
                You can type in the <b> individual email addresses, separated by commas</b>. Or you can copy the link to
                the registry page and paste it in your favorite email platform like Outlook, Gmail or Yahoo.
              </li>
              <li>
                Remember, upon clicking the registry link, your gift givers will be directed to the exact product you
                selected on the retailer site (Walmart.com in our beta test).
              </li>
              <li>
                The gift giver performs the purchase directly with the retailer site, just like any other online
                transaction. We just made it super simple.
              </li>
              <li>Ho ho ho! Wasn't that easy?</li>
            </ul>
          </>
        );

      case 3:
        return (
          <>
            STEP 9: SEND YOUR LIST TO YOUR FAMILY & FRIENDS (PARENTS ONLY)
            <br />
          </>
        );
    }
  };

  return (
    <div>
      {showInfoBox && (
        <div
          className="info-box"
          style={{
            position: 'relative',
            background: '#005424',
            padding: '20px',
            fontSize: '18px',
            color: '#ffffff',
            // margin: '1rem',
          }}
        >
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleInfoBoxClose}
            sx={{
              position: 'absolute',
              top: '5px',
              right: '5px',
              // background: 'black',
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
          <span>{getStepMessage(activeStep)}</span>
          <br />
        </div>
      )}
      <br />
      {isChildDataLoaded && isWishlistLoaded && childInfo && !childInfo.length ? (
        <div className="fetch-data" style={{ marginTop: 0 }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <img src={AddKidsFirst} alt="No Child Profiles Found" style={{ width: '60vh', height: 'auto' }} />
            <Button onClick={() => navigate('/parent/profile')} style={{ width: '40%', alignSelf: 'center' }}>
              Add Child
            </Button>
          </div>
        </div>
      ) : (
        ''
      )}
      {isChildDataLoaded && childInfo && childInfo.length > 0 && (
        <div className="wishlist">
          <div className="page-heading">Registry</div>
          <div className="content">
            <div className="tabs">
              <TabContext value={value}>
                {/* <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="Tabs" >
                      {childInfo.map((child, i) => {
                        return <Tab label={child.childname} value={i + 1} key={i} />;
                      })}
                    </TabList>
                  </Box> */}
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList
                    onChange={handleChange}
                    aria-label="Tabs"
                    variant="scrollable" // Enable scrolling
                    scrollButtons="auto"
                    allowScrollButtonsMobile
                  >
                    {childInfo.map((child, i) => {
                      return (
                        <Tab
                          sx={{
                            fontSize: {
                              xs: '20px',
                              sm: '25px',
                              '&.Mui-selected': {
                                color: 'black', // Red color for active tab
                                fontWeight: 'bold', // Bold weight for active tab
                              },
                            },
                          }}
                          label={child.childname}
                          value={i + 1}
                          key={i}
                        />
                      );
                    })}
                  </TabList>
                </Box>
                <div>
                  {childInfo.map((child, i) => {
                    return (
                      <TabPanel value={i + 1} key={i}>
                        <KidProfile
                          child={child}
                          activeStep={activeStep}
                          setActiveStep={setActiveStep}
                          wishlist={wishlist}
                          isWishlistLoaded={isWishlistLoaded}
                          isChildDataLoaded={isChildDataLoaded}
                        />
                      </TabPanel>
                    );
                  })}
                </div>
              </TabContext>
            </div>
            <br />
          </div>
        </div>
      )}
    </div>
  );
};
