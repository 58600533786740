import { useState } from 'react';
import { Letter } from '../Letter';
import { Button } from '../../../../components/Button';
import { useUser } from '../../../../UserContext';
import { saveWishList } from '../../../../api';
import { Snackbar, Alert } from '@mui/material';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../../../firebase';

export const WriteLetter = ({ setStep, selectedTemplate, selectedProfile }) => {
  const { userData, userId } = useUser();
  const [letterDetails, setLetterDetails] = useState({});
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');

  const sendToSanta = () => {
    const { name, age, haveBeen, did, willDo, greatful, wishlist } = letterDetails;
    // const position = selectedTemplate.id === 1 ? 'right' : selectedTemplate.id === 2 ? 'left' : 'center';
    const position = selectedTemplate.id === 1 ? 'center' : selectedTemplate.id === 2 ? 'center' : 'center';
    const wishlistitem = wishlist ? wishlist.split(',').map(item => item.trim()) : [];

    if (wishlistitem.length > 10) {
      setMessage('Wishlist should not exceed 10 items');
      setOpen(true);
      return;
    }

    const payload = {
      userId: userId || userData.userInfo.userid,
      childWish: [
        {
          childid: selectedProfile.id,
          wishlistitem,
          lettertemplateurl: selectedTemplate.url,
          lettercontent: {
            name,
            age,
            haveBeen,
            did,
            willDo,
            greatful,
            wishlist,
            position,
          },
        },
      ],
    };

    if (!name || name.length === 0) {
      setMessage('Please enter name');
      setOpen(true);
    } else if (!age || age.length === 0) {
      setMessage('Please enter age');
      setOpen(true);
    } else if (!haveBeen || haveBeen.length === 0) {
      setMessage('Please mention how you have been this year');
      setOpen(true);
    } else if (!did || did.length === 0) {
      setMessage('Please write the nice things you did');
      setOpen(true);
    } else if (!willDo || willDo.length === 0) {
      setMessage('Please write the nice things you will do next year');
      setOpen(true);
    } else if (!greatful || greatful.length === 0) {
      setMessage('Please write the nice things you are greateful for');
      setOpen(true);
    } else if (!wishlist || wishlist.length === 0) {
      setMessage('Please write your Christmas wishlist,  press enter once you are done while writing the wishes');
      setOpen(true);
    } else {
      setStep(5);
      saveWishList(payload).then(() => {
        logEvent(analytics, 'Wishlist Created via Letter Writting', { details: payload });
      });
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className="write-letter">
        <div>
          <div style={{ marginBottom: '2rem', width: '100%' }}>
            <p className="page-heading" style={{ marginTop: '0' }}>
              Compose a letter
            </p>
          </div>
        </div>
        <div className="letter">
          <Letter
            selectedTemplate={selectedTemplate}
            setLetterDetails={setLetterDetails}
            letterDetails={letterDetails}
          />
        </div>
      </div>
      <Snackbar
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert elevation={6} variant="filled" onClose={handleClose} severity="error">
          {message}!
        </Alert>
      </Snackbar>
      <div className="template-buttons" style={{ marginBottom: '2rem' }}>
        <Button onClick={() => setStep(3)}>Previous</Button>
        <Button onClick={() => sendToSanta()}>Send to Santa</Button>
      </div>
    </>
  );
};
