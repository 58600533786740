import './index.css';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export const ProfileCard = ({ avatar, name, isActive, setActive, sentToSanta }) => {
  return (
    <div className={`kid-card ${isActive ? 'active' : ''}`} onClick={setActive}>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <span style={{ display: 'inline-block', height: '20px', width: '20px' }}>
          {sentToSanta && <CheckCircleIcon color="success" />}
        </span>
      </div>
      <img width="100" height="160" src={avatar} alt="" />
      <p>{name}</p>
    </div>
  );
};
